import React from "react";
import "icofont/dist/icofont.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import './styles/custom.scss';
import "./styles/colors.css";
import "./styles/blue.css";
import "./styles/responsive.css";
import "./styles/App.css";
import "./styles/style.css";
import Header from "./components/Header";
import Banner from "./components/Banner";
import EmailInputGroup from "./components/EmailInputGroup";
import AboutSlider from "./components/AboutSlider";
import ClientMarquee from "./components/ClientMarquee";
import PricingTable from "./components/PricingTable";
import Footer from "./components/Footer";
import About from "./components/About";
import Services from "./components/Services";
import Team from "./components/Team";
import Portfolio from "./components/Portfolio";
import Marketing from "./components/Marketing";
import LatestNews from "./components/LatestNews";
import OurBranch from "./components/OurBranch";
import ContactUs from "./components/ContactUs";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Header />

        <div id="home" className="section">
          <Banner />
        </div>

        {/* <EmailInputGroup /> */}

        <About />

        <Services />

        <Portfolio />

        <AboutSlider />

        {/* <Marketing /> */}

        <Team />

        <ClientMarquee />

        {/* <PricingTable /> */}

        {/* <LatestNews /> */}

        <ContactUs />

        <OurBranch />

        <Footer />
      </div>
    );
  }
}

export default App;