import React from "react";
import { Row, Col, Container } from "reactstrap";
import "../styles/AboutSlider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class AboutSlider extends React.Component {
  render() {
    return (
      <div className="section about-box grapping bg-parallax" id="about">
        <Container>
          <div className="title-box">
            <h2>About</h2>
            <p>
              Dimple Enterprises is a premier consulting and staffing firm
              dedicated to bridging the gap between skilled professionals and
              thriving industries. With decades of experience, we specialize in
              providing comprehensive staffing solutions, talent acquisition,
              and career development services tailored to meet the unique needs
              of factories and industries.
            </p>
          </div>

          <Row>
          <Col xs="12" sm="12" md="12" lg="6">
              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  <div className="about-img">
                    <img
                      src={require("../images/aboutus.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </Col>
                <Col xs="12" sm="12" md="12" lg="12">
                <div className="common-box">
                  <h4>Why Choose Us</h4>
                  <p>
                    Dimple Enterprises is dedicated to delivering
                    high-quality, client-centric services with a strong
                    emphasis on ethical practices. We specialize in flexible
                    staffing solutions, including temporary staffing and
                    headcount outsourcing, while also supporting skill
                    development initiatives to meet the evolving demands of
                    the industry.
                  </p>

                  <h4>Here's why we stand out:</h4>
                    <p><strong>Certified Enterprise: </strong>We adhere to high standards and certifications in all our services.</p>
                    <p><strong>Client-Focused: </strong>Tailored services to meet the unique needs of each client.</p>
                    <p><strong>Skilled Workforce: </strong>We provide a well-trained, competent workforce across various industries.</p>
                    <p><strong>Training Programs: </strong>We offer extensive training programs to prepare candidates for industrial employment.</p>
                    <p><strong>Broad Reach: </strong>Serving multiple cities, including Pune and Ahmednagar, with a wide spectrum of opportunities.</p>
                    <p><strong>Trusted Partner: </strong>We are a trusted staffing partner for leading companies across diverse sectors.</p>
                </div>
                </Col>
              </Row>
            </Col>
            <Col xs="12" sm="12" md="12" lg="6">
              <Row className="gap-4">
                <Col xs="12" sm="12" md="12">
                  <div className="white-bg wel-box common-box media">
                    <p>
                      Dimple Enterprises was founded in 2023 with a vision to
                      provide meaningful employment opportunities and to support
                      industries with a skilled workforce. We understand the
                      importance of matching the right talent with the right job
                      and strive to bridge the gap between job seekers and
                      employers. Our commitment to excellence, ethical practices,
                      and a client-centric approach sets us apart in the staffing
                      industry.
                    </p>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="12">
                  <div className="white-bg wel-box common-box media">
                    <h4>Our Mission</h4>
                    <p>
                      Our mission is to reduce unemployment by creating a bridge
                      between the educated youth and the industries that require
                      their skills. We aim to empower individuals through skill
                      development and provide them with opportunities for both
                      industrial employment and self-employment. By fostering
                      discipline and dedication, we help our candidates achieve
                      their career goals.
                    </p>
                  </div>
                </Col>
                <Col xs="12" sm="12" md="12">
                  <div className="white-bg wel-box common-box media">
                    <h4>Our Vision</h4>
                    <p>
                      Our vision is to be the leading staffing provider in the
                      region, known for our ethical practices, commitment to
                      quality, and innovative solutions. We strive to create a
                      sustainable workforce that meets the ever-changing needs
                      of industries while also empowering individuals to achieve
                      their personal and professional aspirations.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AboutSlider;
