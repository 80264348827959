import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import "../styles/LatestNews.css";

class LatestNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    return (
      <div className="section latest-news grapping" id="blog">
        <Container>
          <div className="title-box">
            <h2>
              Our Latest <b>News</b>
            </h2>
            <p>
              Dignissimos ducimus qui blanditiis praesentium voluptatum deleniti
              atque corrupti quos dolores
            </p>
          </div>
          <Row>
            <Col xs="12" sm="12" md="4">
              <Card>
                <CardImg
                  top
                  width="100%"
                  src={require("../images/blog-new1.jpg")}
                  alt="Card image cap"
                />
                <Card body>
                  <CardTitle>Dignissimos ducimus qui </CardTitle>
                  <CardText>
                    With supporting text below as a natural lead-in to
                    additional content.
                  </CardText>
                  <Button color="primary">Read More</Button>
                </Card>
              </Card>
            </Col>
            <Col xs="12" sm="12" md="4">
              <Card>
                <CardImg
                  top
                  width="100%"
                  src={require("../images/blog-new2.jpg")}
                  alt="Card image cap"
                />
                <Card body>
                  <CardTitle>Dignissimos ducimus qui </CardTitle>
                  <CardText>
                    With supporting text below as a natural lead-in to
                    additional content.
                  </CardText>
                  <Button color="primary">Read More</Button>
                </Card>
              </Card>
            </Col>
            <Col xs="12" sm="12" md="4">
              <Card>
                <CardImg
                  top
                  width="100%"
                  src={require("../images/blog-new3.jpg")}
                  alt="Card image cap"
                />
                <Card body>
                  <CardTitle>Dignissimos ducimus qui</CardTitle>
                  <CardText>
                    With supporting text below as a natural lead-in to
                    additional content.
                  </CardText>
                  <Button color="primary">Read More</Button>
                </Card>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default LatestNews;
