import React from "react";
import "../styles/ClientMarquee.css";
import { Container } from "reactstrap";

class ClientMarquee extends React.Component {
  render() {

    var clients = [
      {
        name: 'PG Electroplast LTD',
        logo: require('./../images/client/client-logo1.png')
      },
      {
        name: 'PG Technoplast PVT LTD',
        logo: ''
      },
      {
        name: 'Next Generation Manufacturers PVT LTD',
        logo: require('./../images/client/client-logo2.jpg')
      },
      {
        name: 'Crafted Solutions',
        logo: require('./../images/client/client-logo4.png')
      },
      {
        name: 'Shri Venkatesh Polycore PVT LTD',
        logo: ''
      },
      {
        name: 'Okay Industries',
        logo: require('./../images/client/client-logo3.jpg')
      }
    ]

    let client_array = clients.map((client, key) => {
      return (
        <div className="item">
                <div className="feedback-box">
                  <div className="client-info">
                    {client.logo !== '' && <div className="client-img">
                      <img
                        src={client.logo}
                        alt=""
                        className="img-fluid"
                      />
                    </div>}
                    {client.logo === '' && <h5 className="mb-2">{client.name}</h5>}
                  </div>
                </div>
              </div>
      );
    });

    return (
      <div className="client-marquee grapping">
        <Container>
          <div className="title-box">
            <h2>
              <b>Our Clients</b>
            </h2>
          </div>
          <div className="marquee">
            <div className="marquee-content">
              {client_array}
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default ClientMarquee;
