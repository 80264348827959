import React from "react";
import "../styles/Services.css";
import { Container, Row, Col } from "reactstrap";

class Services extends React.Component {
  render() {
    return (
      <div className="section our-service grapping" id="services">
        <Container>
          <div className="title-box">
            <h2>
              Our <b>Services</b>
            </h2>
            <p>
              Engage with employees to offer them the best opportunities for advancing their careers and enhancing their lives.
              This commitment is linked to our responsibility in managing industry relations, ensuring that the company fulfills its duty to provide growth opportunities.
            </p>
          </div>
          <Row>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-crown media-icon" />
                <h4>Permanent Staffing</h4>
                <p>
                  Our team specializes in recruiting top talent for permanent positions, ensuring that businesses find the right fit for long-term success.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-pen-alt-3 media-icon" />
                <h4>Temporary Staffing Solutions</h4>
                <p>
                  We provide skilled and unskilled workers on a temporary basis to meet the fluctuating demands of industries and factories.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-camera-alt media-icon" />
                <h4>Contract Staffing</h4>
                <p>
                  We offer contract-based staffing solutions, allowing companies to hire professionals for specific projects or timeframes.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-chart-growth media-icon" />
                <h4>Training & Development</h4>
                <p>
                  We conduct training programs to equip potential candidates with the necessary skills and certifications required by various industries.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-laptop-alt media-icon" />
                <h4>Recruitment Process Outsourcing (RPO)</h4>
                <p>
                  Our RPO services streamline the recruitment process for companies, from candidate sourcing to onboarding.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-tick-boxed media-icon" />
                <h4>Workforce Management</h4>
                <p>
                  We assist in managing the workforce, including payroll services, compliance, and employee relations.
                </p>
              </div>
            </Col>
            <Col xs="15" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-paper media-icon" />
                <h4>On-Site Management</h4>
                <p>
                  Our on-site management service ensures seamless operation and coordination of staffing solutions at the client’s location.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="6" md="6" lg="3">
              <div className="white-bg common-box our-service-box media">
                <i className="icofont-search-job media-icon" />
                <h4>Executive Search</h4>
                <p>
                  We specialize in identifying and recruiting senior-level executives who can drive organizational growth and success.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Services;
