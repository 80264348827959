import React from "react";
import "../styles/Team.css";
import { Container, Row, Col } from "reactstrap";

class Team extends React.Component {
  render() {
    return (
      <div className="section expert-team grapping" id="team">
        <Container>
          <div className="title-box">
            <h2><b>Meet Our CEO</b></h2>
            {/* <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book</p> */}
          </div>
          <Row>
            <Col xs="12" sm="12" md="12" lg="6">
              <div className="white-bg wel-box common-box media">
              <div className="team-img">
                  <img src={require("../images/ceo.png")} alt="" className="img-fluid rounded-circle" />
                </div>
                <h4 className="text-center text-black">Dimple Upadhyay</h4>
                <p>
                  <strong>CEO's Message: </strong> 
                  <span className="ceo-message">"At Dimple Enterprises, we believe in creating opportunities that not only fulfill the immediate needs of our
                    clients but also contribute to the long-term success of both businesses and individuals. Our commitment to excellence,
                    ethical practices, and client satisfaction is at the heart of everything we do. Together, we can build a future where every
                    individual finds a meaningful place in the workforce, and every business achieves its full potential."
                  </span>
                </p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="12" lg="6">
              <div className="white-bg wel-box common-box media">
                <h4>Dimple Upadhyay's Journey and Vision</h4>
                <p>
                  Dimple Upadhyay’s journey in the staffing and human resources industry spans over 2 years,
                  during which she has made significant contributions to the field. Her career is marked by a
                  relentless pursuit of excellence, innovation, and a deep commitment to empowering individuals and businesses alike.
                </p>
                <br />
                <p>
                <strong>Vision for Dimple Enterprises: </strong>
                  Dimple envisions Dimple Enterprises as more than just a staffing company. Her goal is to create a platform
                  that not only connects businesses with the right talent but also nurtures and develops that talent to meet
                  the future demands of the industry. She believes in a holistic approach where the growth of the company is
                  directly linked to the growth and success of the individuals it serves.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Team;
