import React from "react";
import { Col, Container, Row } from "reactstrap";
import "../styles/OurBranch.css";

class OurBranch extends React.Component {
  render() {
    return (
      <div className="our-branch grapping">
        <Container>
          <Row className="our-branch-info">
            <Col md="3">
              <div className="logo-2">
                <img src="../logo/logo-2.svg" alt="logo" />
              </div>
            </Col>
            <Col md="3">
              <h4>Our Branches</h4>
              <ul>
                <li>Pune</li>
                <li>Ahmednagar</li>
              </ul>
            </Col>
            <Col md="3">
              <h4>Contact Us</h4>
              <p>
                <strong>Address:</strong> Mauli green park, walone road midc supa tah. Parner, Ahmednagar, MH-414301

              </p>
              <p>
                <strong>Mobile:</strong> +918421569779
              </p>
              <p>
                <strong>Email:</strong> <a href="mailto:info@dimpleenterprises.com">info@dimpleenterprises.com</a>
              </p>
            </Col>
            <Col md="3">
              <h4>Location</h4>
              <iframe
                title="company-location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d290.2000369928323!2d74.5425747320391!3d18.952551491531892!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bdccb0fdb6c84bf%3A0x89286109c26a8807!2sMauli%20Green%20Park!5e1!3m2!1sen!2sin!4v1725454614732!5m2!1sen!2sin"
                width="100%"
                height="200"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default OurBranch;
