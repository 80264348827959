import React from "react";
import "../styles/About.css";
import logoB from "../logoBlack.svg";
import { Container, Row, Col } from "reactstrap";

class About extends React.Component {
  render() {
    return (
      <div className="about welcome-box grapping">
        <Container>
          <div className="title-box">
            <h2>
              <span>Welcome to </span><img src={logoB} className="logo-black" alt="logo" />
            </h2>
            <p>
              Dimple Enterprises is dedicated to connecting skilled labor with
              job opportunities that match their talents and interests.
            </p>
          </div>
          <Row>
            <Col xs="12" sm="12" md="4">
              <div className="white-bg wel-box common-box media">
                <i className="icofont-live-support media-icon" />
                <h4>Comprehensive Staffing and HR Solutions</h4>
                <p>
                  Dimple Enterprises offers a wide range of staffing and human
                  resources management solutions tailored to meet the diverse
                  needs of industries. Our services include staffing, payroll
                  management, HR consulting, compliance management, and training
                  and development, all designed to streamline operations and
                  enhance productivity.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="4">
              <div className="white-bg wel-box common-box media">
                <i className="icofont-code media-icon" />
                <h4>Technology-Driven and Client-Centric Approach</h4>
                <p>
                  Our technology-driven solutions seamlessly integrate with
                  clients' existing systems, enabling businesses to optimize
                  efficiency and profitability. We are proud to be the preferred
                  staffing partner for various industries, including
                  manufacturing, logistics, healthcare, and more.
                </p>
              </div>
            </Col>
            <Col xs="12" sm="12" md="4">
              <div className="white-bg wel-box common-box media">
                <i className="icofont-drag2 media-icon" />
                <h4>Commitment to Quality and Ethical Standards</h4>
                <p>
                  Dimple Enterprises is dedicated to delivering high-quality,
                  client-centric services with a strong emphasis on ethical
                  practices. We specialize in flexible staffing solutions,
                  including temporary staffing and headcount outsourcing, while
                  also supporting skill development initiatives to meet the
                  evolving demands of the industry.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default About;
