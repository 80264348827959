import React from "react";
import {
  InputGroup,
  InputGroupAddon,
  Button,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import "../styles/EmailInputGroup.css";

class EmailInputGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
  }

  onButtonClick = (e) => {
    this.setState({ email: "" });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { email } = this.state;
    return (
      <div className=" subscribe-box">
        <Container>
          <Row className="align-items-center">
            <Col xs="12" md="12" lg="8" className="text-left">
              <h4 className="text-white">
                {" "}
                Join the Dimple Enterprises! Sign up for the latest updates and
                news.
              </h4>
            </Col>
            <Col xs="12" md="12" lg="4">
              <InputGroup className="news-box">
                <Input
                  className="sub-control"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="name@gmail.com"
                  value={email}
                  onChange={this.handleChange}
                />
                <InputGroupAddon addonType="append">
                  <Button color="secondary" onClick={this.onButtonClick}>
                    {" "}
                    <i class="icofont-email"></i>
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EmailInputGroup;
