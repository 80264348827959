import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Media,
  Row,
} from "reactstrap";
import "../styles/ContactUs.css";

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      qualification: "",
      email: "",
      mobile: "",
      address: "",
      preferredLocation: "",
      jobType: "",
      message: "",
      resume: null,
      feedbackMessage: "",
      isFormValid: false,
    };
  }

  handleChange = (e) => {
    const { name, value, files } = e.target;
    this.setState(
      {
        [name]: files ? files[0] : value,
      },
      this.validateForm
    );
  };

  validateForm = () => {
    const { name, qualification, email, mobile, address, preferredLocation, jobType, message, resume } =
      this.state;

    const isFormValid =
      name.trim() &&
      qualification.trim() &&
      email.trim() &&
      mobile.trim() &&
      address.trim() &&
      preferredLocation.trim() &&
      jobType.trim() &&
      message.trim() &&
      resume;

    this.setState({ isFormValid });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ feedbackMessage: "Sending..." });
    const formData = new FormData();
    const { name, qualification, email, mobile, address, preferredLocation, jobType, message, resume } =
      this.state;

    // Append each field to FormData
    formData.append("name", name);
    formData.append("qualification", qualification);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("address", address);
    formData.append("preferredLocation", preferredLocation);
    formData.append("jobType", jobType);
    formData.append("message", message);

    // Append file if exists
    if (resume) {
      formData.append("resume", resume);
    }

    // Log FormData (for debugging)
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    try {
      fetch("https://api.dimpleenterprises.com/api/contact", {
        method: "POST",
        body: formData,
      }).then((response) => {
        if (response.ok) {
          this.setState({
            feedbackMessage: "Message sent successfully!",
            name: "",
            email: "",
            mobile: "",
            address: "",
            preferredLocation: "",
            jobType: "",
            message: "",
            resume: null,
            qualification: "",
            isFormValid: false,
          });
        } else {
          this.setState({ feedbackMessage: "Failed to send message." });
        }
        setTimeout(() => this.setState({ feedbackMessage: "" }), 5000);
      });
    } catch (error) {
      console.error("Error:", error);
      this.setState({ feedbackMessage: "Failed to send message." });
    }
  };

  render() {
    const {
      name,
      qualification,
      email,
      mobile,
      address,
      preferredLocation,
      jobType,
      message,
      feedbackMessage,
      isFormValid,
    } = this.state;

    return (
      <div className="section contact-us grapping" id="contact">
        <Container>
          <div className="title-box">
            <h2>
              Contact <b>Us</b>
            </h2>
            <p>
              Mauli green park, walone road midc supa tah. Parner, Ahmednagar, MH-414301
            </p>
          </div>
          <Row>
            <Col xs="12" md="12" lg="4">
              <Media className="address-box">
                <Media left>
                  <i className="icofont-location-pin" />
                </Media>
                <Media body>
                  <Media heading>Address</Media>
                  <p>
                    {" "}
                    Mauli green park, walwane road midc supa tah. Parner, Ahmednagar, MH-414301
                  </p>
                </Media>
              </Media>
            </Col>
            <Col xs="12" md="6" lg="4">
              <Media className="address-box">
                <Media left>
                  <i className="icofont-mail" />
                </Media>
                <Media body>
                  <Media heading>Email</Media>
                  <a href="mailto:info@dimpleenterprises.com">
                    {" "}
                    Info@dimpleenterprises.com
                  </a>{" "}
                  <br />
                  {/* <a href="mailto:info@dimpleenterprises.com">support@dimpleenterprises.com</a> */}
                </Media>
              </Media>
            </Col>
            <Col xs="12" md="6" lg="4">
              <Media className="address-box">
                <Media left>
                  <i className="icofont-phone" />
                </Media>
                <Media body>
                  <Media heading>Phone</Media>
                  <a href="tel:+918421569779">+91 8421569779</a>
                </Media>
              </Media>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs="12" md="12" lg="4">
              <h4>Get in Touch</h4>
              <hr />
              <p>
                Whether you represent a business seeking outstanding talent or a professional in pursuit of new career paths,
                we are here to assist you. Please contact us today to outline your needs and explore how Dimple Enterprises can
                facilitate your ambitions. Our experienced team is available to provide customized support and respond to any
                questions you might have. Let us collaborate to reach your goals.
              </p>
              <ListGroup className="social-icon">
                <ListGroupItem tag="a" href="#">
                  {" "}
                  <i className="icofont-facebook" />
                </ListGroupItem>
                <ListGroupItem tag="a" href="#">
                  {" "}
                  <i className="icofont-twitter" />
                </ListGroupItem>
                <ListGroupItem tag="a" href="#">
                  {" "}
                  <i className="icofont-instagram" />
                </ListGroupItem>
                <ListGroupItem tag="a" href="#">
                  {" "}
                  <i className="icofont-linkedin" />
                </ListGroupItem>
                <ListGroupItem tag="a" href="#">
                  {" "}
                  <i className="icofont-youtube-play" />
                </ListGroupItem>
              </ListGroup>
            </Col>

            <Col xs="12" md="12" lg="8">
              <Form onSubmit={this.handleSubmit} className="mt-4">
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        value={name}
                        onChange={this.handleChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="qualification"
                        id="qualification"
                        placeholder="Highest Qualification"
                        value={qualification}
                        onChange={this.handleChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="mobile"
                        id="mobile"
                        placeholder="Mobile"
                        value={mobile}
                        onChange={this.handleChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        value={email}
                        onChange={this.handleChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    value={address}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="preferredLocation"
                        id="preferredLocation"
                        placeholder="Preferred Location"
                        value={preferredLocation}
                        onChange={this.handleChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="select"
                        name="jobType"
                        id="jobType"
                        value={jobType}
                        onChange={this.handleChange}
                        required
                      >
                        <option value="">Select Job Type</option>
                        <option value="Helper">Helper</option>
                        <option value="Operator">Operator</option>
                        <option value="Trainee">Trainee</option>
                        <option value="Welder">Welder</option>
                        <option value="Fitter">Fitter</option>
                        <option value="Press Operator">Press Operator</option>
                        <option value="Moulding MC Operator">Moulding MC Operator</option>
                        <option value="CNC Operator">CNC Operator</option>
                        <option value="Electrician">Electrician</option>
                        <option value="PCB Technician">PCB Technician</option>
                        <option value="Loading">Loading</option>
                        <option value="Unloading">Unloading</option>
                        <option value="Other">Other</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    placeholder="Your message"
                    value={message}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="resume">Attach Resume</Label>
                  <Input
                    type="file"
                    name="resume"
                    id="resume"
                    hidden
                    onChange={(e) => {
                      const fileName = e.target.files[0]?.name || "Choose file";
                      document.getElementById("file-label").innerText =
                        fileName;
                      this.handleChange(e);
                    }}
                    required
                  />
                  <Label
                    for="resume"
                    id="file-label"
                    className="btn btn-outline-secondary"
                    style={{ cursor: "pointer" }}
                  >
                    Choose file
                  </Label>
                </FormGroup>
                <Button color="primary" disabled={!isFormValid}>
                  Send Message
                </Button>
              </Form>

              {/* Feedback Message */}
              {feedbackMessage && (
                <div
                  style={{
                    marginTop: "20px",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    backgroundColor:
                      feedbackMessage === "Message sent successfully!"
                        ? "#d4edda"
                        : feedbackMessage === "Sending..."
                        ? "#c9e6ff"
                        : "#f8d7da",
                    color:
                      feedbackMessage === "Message sent successfully!"
                        ? "#155724"
                        : feedbackMessage === "Sending..."
                        ? "#2c7c27"
                        : "#721c24",
                  }}
                >
                  {feedbackMessage}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ContactUs;
