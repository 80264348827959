import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../styles/Footer.css";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="footer bg-black-grey">
        <footer>
          <Container>
            <Row className="align-items-center">
              <Col md="6">
                <p>Copyright @ 2024 Dimple Enterprises. All rights reserved</p>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
}

export default Footer;
